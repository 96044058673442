<template>
<div class="head-container">
  <el-input
      v-model="basicInfo"
      clearable
      placeholder="客户姓名/ID"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
  >搜索</el-button>
</div>
</template>

<script>
export default {
  name: 'eheader',
  data() {
    return {
      basicInfo: '',
    }
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){

    }
  }
}
</script>

<style scoped>

</style>