<template>
  <div class="app-container summary-page trading-record">
    <eHeader
      ref="eheader"
      @toQuery="toQueryHandle"
    ></eHeader>
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
        show-summary
        :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="序号"/>
      <el-table-column prop="orderId" label="客户信息">
        <template slot-scope="scope">
          <div>{{scope.row.custName}}</div>
          <span>{{scope.row.custId}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalInter" label="总积分">
        <template slot-scope="scope">
          <span
              class="total-inter"
              @click="interHandle(scope.row)"
          >{{scope.row.totalPoint}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="consumedPoint" label="已兑换" />
      <el-table-column prop="expiredPoint" label="已过期" />
      <el-table-column prop="availablePoint" label="可用积分" />
    </el-table>

    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/intergratingManage/customerIntegral/eheader'
export default {
  name:'customerIntegral',
  components: {
    eHeader,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      statistics: {
        availablePointAmount: 0,
        consumedPointAmount: 0,
        expiredPointAmount: 0,
        totalPointAmount: 0,
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      let header = this.$refs.eheader;
      this.url = 'crm/rewardPoint';
      this.params = {
        page: this.page,
        size: this.size,
      }
      if(header.basicInfo){
        this.params.custInfo = header.basicInfo;
      }
      return true
    },
    getInfo(row){

    },
    interHandle(row){

      // 跳转到积分明细,带客户ID查询条件
      this.$store.commit('setCustomerIntergralJumpToAuditListOfId',row.custId);
      this.$router.push({path: '/pt/intergratingManage/auditList'});
    },
    toQueryHandle(){
      this.page = 0;
      this.init();
    },
    getSummaries(param) {

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // 需要改成column.props
        switch (column.property) {
          case 'id':
            sums[index] = '合计';
            break;
          case 'orderId':
            sums[index] = '-';
            break;
          case 'totalInter':
            sums[index] = `${this.statistics.totalPointAmount}`;
            break;
          case 'consumedPoint':
            sums[index] = `${this.statistics.consumedPointAmount}`;
            break;
          case 'expiredPoint':
            sums[index] = `${this.statistics.expiredPointAmount}`;
            break;
          case 'availablePoint':
            sums[index] = `${this.statistics.availablePointAmount}`;
            break;
        }
      });

      return sums;
    },
  }
}
</script>

<style lang="less">
  .trading-record {
    .el-table {
      .el-table__footer-wrapper {
        .has-gutter {
          tr {
            .is-leaf:nth-of-type(6) {
              .cell {
                white-space: pre-wrap !important;
              }
            }
          }
        }
      }
    }
    .total-inter {
      color: #409EFF;
      cursor: pointer;
    }
  }
</style>
